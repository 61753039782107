const CURP_DICTIONARY = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';

// eslint-disable-next-line import/prefer-default-export
export function getCurpCheckDigit(curp: string): string {
  let counter = 0.0;
  let digit = 0.0;

  for (let index = 0; index < 17; index += 1) {
    counter += CURP_DICTIONARY.indexOf(curp.charAt(index)) * (18 - index);
  }

  digit = 10 - (counter % 10);

  if (digit === 10) {
    return '0';
  }

  return digit.toString();
}

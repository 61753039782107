import i18n from '@/vue-app/plugins/i18n';
import { getCurpCheckDigit } from '@/vue-app/utils/curp';
import { verifyRfcCheckDigit } from '@/vue-app/utils/rfc';

export type RuleResponseType = string | boolean;

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_CURP = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
const REGEX_RFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
const REGEX_ELECTOR_KEY = /^[A-Z]{6}[0-9]{8}[A-Z][0-9]{3}$/;
const REGEX_BANK_ACCOUNT = /^\d{18}$/;
const REGEX_ONLY_NUMBER = /^\d+$/;
const REGEX_PHONE = /^([\d] *){10}$/;
const REGEX_FIEL = /^[\d]{20,25}$/;

const REGEX_PERCENTAGE = /^[1-9][0-9]?$|^100$/;

export const requiredRule = (value: string): RuleResponseType => !!value || i18n.t('shared.form-rules.required').toString();
export const emailFormat = (value: string): RuleResponseType => REGEX_EMAIL.test(value) || i18n.t('shared.form-rules.email_format').toString();
export const curpFormat = (value: string): RuleResponseType => {
  if (value === '') {
    return true;
  }

  const validatedCurp = value && value.match(REGEX_CURP);

  if (!value) {
    return true;
  }

  if (!validatedCurp) {
    return i18n.t('shared.form-rules.curp_format').toString();
  }

  if (validatedCurp[2] !== getCurpCheckDigit(value)) {
    return i18n.t('shared.form-rules.curp_format').toString();
  }

  return true;
};
export const rfcFormat = (value: string): RuleResponseType => {
  const validatedRfc = value && value.match(REGEX_RFC);

  if (!validatedRfc) {
    return i18n.t('shared.form-rules.rfc_format').toString();
  }

  if (!verifyRfcCheckDigit(validatedRfc)) {
    return i18n.t('shared.form-rules.rfc_format').toString();
  }

  return true;
};
export const electorKeyFormat = (value: string): RuleResponseType => REGEX_ELECTOR_KEY.test(value) || i18n.t('shared.form-rules.elector_key_format').toString();
export const bankAccountFormat = (value: string): RuleResponseType => REGEX_BANK_ACCOUNT.test(value) || i18n.t('shared.form-rules.bank_account_key_format').toString();
export const fileSize = (size: number | null) => (value: File): RuleResponseType => value && size && size > value.size || i18n.t('shared.form-rules.file_size').toString();
export const fileType = (types: Array<string> | null) => (value: File): RuleResponseType => value && types && types.some((r) => value.type.split('/').indexOf(r) >= 0) || i18n.t('shared.form-rules.file_extension').toString();
export const notNull = (value: string): RuleResponseType => value !== null && value !== undefined || i18n.t('shared.form-rules.select_any_option').toString();
export const onlyNumbers = (value: string): RuleResponseType => (!!value && REGEX_ONLY_NUMBER.test(value)) || i18n.t('shared.form-rules.only_numbers').toString();
export const percentageFormat = (value: string): RuleResponseType => REGEX_PERCENTAGE.test(value) || i18n.t('shared.form-rules.percentage_format').toString();
export const phoneFormat = (value: string): RuleResponseType => (value == null || value === '' || REGEX_PHONE.test(value)) || i18n.t('shared.form-rules.phone_format').toString();

export function fielFormat(value: string) {
  if (value === '') {
    return true;
  }

  if (REGEX_FIEL.test(value)) {
    return true;
  }

  return i18n.t('shared.form-rules.fiel_format').toString();
}
